import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Layout } from "../Layout";
import ProductBanner from "../product_banners/product_banners";
import Banner from "../../imgs/bg_glosario.jpg";
import ArticulosRelacionados from "./articulos_relacionados";
import Button from "../buttons/button";
import Icon from "../icon/icon";
import Mail from "../../imgs/share_mail.svg";
import Twitter from "../../imgs/share_twitter.svg";
import Linkedin from "../../imgs/share_linkedin.svg";
import Whatsapp from "../../imgs/share_whatsapp.svg";
import './articles.css';
import { apiRequest } from "../../apiRquest/apiRequest";
import { SalWrapper } from "react-sal"

const ArticuloPage = ({ artid, slug, title, metatitle, description, imgurl, htmlcontent, htmldesc, faq_glosario }) => {

    let text = `Aprendé los términos financieros más importantes y empezá a<br/> <span class='highlightsbold'>invertir como un experto.</span>`;

    const baseUrl = `${process.env.GATSBY_BASE_URL}`;
    const pageTitle = `${metatitle}`;
    const pageDesc = `${description}`;
    const imgUrl = imgurl;
    const shareUrl = encodeURIComponent(baseUrl + '/balanz-university/glosario-financiero/' + slug)
    const [articulosrel, setArticulosrel] = useState([])
    useEffect(() => {

        apiRequest.get('/v1/university')
            .then(response => {
                const articulosrelFull = response.data.map(d => ({ node: d }));
                const articulosrelFiltered = articulosrelFull.filter(element => element.node.artid !== artid)
                const articulosrelsorted = articulosrelFiltered.sort((a, b) => new Date(b.node.timestamp) - new Date(a.node.timestamp));
                setArticulosrel(articulosrelsorted.filter((r, i) => i < 4))
            })

    }, [])

     // PREGUNTAS FRECUENTES
     const [questions, setQuestions] = useState([]);
    
    
     useEffect(()=>{
 
         let fatQ= {};
 
         if(faq_glosario == null || faq_glosario === undefined ){
             setQuestions(0);
 
         } else {
             
             fatQ=JSON.parse(faq_glosario);
            
             if(faq_glosario.length > 0 ){
 
                 let dataFat = [];
                 fatQ.data.forEach(function(item) {
                 
                 dataFat.push({
                     questions: item.titulo,
                     link: item.enlace,
                 });
             
             });
             setQuestions(dataFat);
 
             } else { 
             setQuestions(0);
             }
 
 
         }
 
     },[])


    useEffect(() => {
        getReadingTime(htmlcontent)
    },[])

    function getReadingTime(htmlcontent) {
        let words3 = htmlcontent;
        var words, time, minutes, seconds;
        words = words3.split(' ').length;
        time = (60 * words / 250)
        minutes = ~~(time / 60);
        seconds = Math.ceil(time - minutes * 60);
        if (seconds >= 30) {
            return `${minutes + 1}m`
        } else if (seconds < 30) {
            return `${minutes}m`
        }
    }
    const processedHtml =  (htmldesc || '').replace(/<\/?p>/g, '');
    const page = (
        <>
            
            <ProductBanner
                headingType="h2"
                bannerContainerClassName="bannerUniversity"
                bannerTrasparecy="bannerMask"
                productTitle="Glosario Financiero"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
            <section className="fullwidth blnz-article-container">
            
                <Container>
                <Row>
                        <Col xs={12}>
                            <div className="blnz-article">
                                <Row className="row d-flex justify-content-between align-content-end art-header align-items-center">
                                    <Col xs={12} md={8} className="art-title order-2 order-md-1">
                                        <h1 className="main-title mt-2 mb-2">
                                           
                                            <span onClick={() => { window.open("/balanz-university/glosario-financiero","_self") }} style={{fontSize: '20px',  color:'#0D2055', textDecoration: 'none', fontWeight: 'bold'}} className="div-text-link-glosario"> Glosario Financiero</span>
                                            <span style={{fontSize: '20px',  color:'#0D2055', textDecoration: 'none', fontWeight: 'bold'}}> &rsaquo; {title}</span>
                                        </h1>
                                        <h3 className="mt-2 mb-4">
                                            <span className="sub-title base-text">
                                           </span>
                                        </h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                    
                                <Row className="row d-flex justify-content-between align-content-end art-header align-items-center">
                                    <Col xs={12} md={12} className="art-title order-2 order-md-1">
                                        <h1 className="main-title mt-2 mb-2 ">
                                           <span style={{fontSize: '40px',  color:'#0D2055', textDecoration: 'none', fontWeight: 'bold'}}>{title}</span>
                                        </h1>
                                        <br/>
                                        <h5 className="main-title mt-2 mb-2">
                                        <span className="bigger-title-glosario2" dangerouslySetInnerHTML={{ __html: processedHtml }} />   
                                       
                                        </h5>
                                        <br/>
                                        
                                    </Col> 
                                </Row>
                        </Col>

                        
                    </Row>

                    <section className="fullwidthbg-glo-details py-2">
                    <Container className="d-flex align-items-center justify-content-center" >
                   <Row className="d-flex  py-3 py-sm-4  gx-5 w-100" >
                   <Col xs={12} md={2} >
                                <h1  style={{  fontSize: '90px',color:' #00A3E4', fontWeight: 'normal' }}>$0</h1>
                        </Col> 
                   <Col xs={12} md={6}>
                            <h4 className="titulo me-3 me-md-3 me-lg-4 me-xl-5"  style={{ color:' #000000' }} ><span className="highlightsbold">$0 costo de apertura y de mantenimiento. 
                            <br className="d-none d-lg-inline" />
                                 Sin mínimo de inversión. 
                                </span>
                            <br className="d-none d-lg-inline" />
                            <span className="bigger-title-glosario3">
                                Transformá tu ahorro en inversión.
                            </span>
                            </h4>
                        
                    </Col>
                    <Col xs={12} md={2} className="d-flex aling-items-center">
                                <a className="btn btn-secondary m-auto" href="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1399">
                                    Abrir Cuenta
                                </a>
                        </Col>


                  </Row>
            </Container>
        </section>


                    <Row>
                        <Col xs={12}>
                            <div className="blnz-article">
                                <Row>
                                    <Col xs={12}>
                                        <div
                                            className="article-content"
                                            dangerouslySetInnerHTML={{ __html: htmlcontent }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="text-center pt-5">
                            <Link to="/balanz-university/glosario-financiero">
                                <Button variant="secondary" text="Ir al Glosario Financiero"/>
                            </Link>
                        </div>
                    </Row>
                </Container>
            </section>
            
            { questions.length > 0 &&
                <section className="fullwidth blnz-article-container">
                    <Container>
                        <h2 className="mb-2">Información relacionada</h2><br />
                        <Col xs={12} md={12} className="text-start " >
                                        {
                                        questions.map((item, index) => {
                                            return (     
                                                    <div key={index} >
                                                    {
                                                        <>
                                                        <Link to={item.link}>                                                    
                                                        <p style={{fontSize: '21.6px',  color:'#828282'}} className="div-link-nav">{item.questions}</p>  
                                                        </Link>
                                                        </>
                                                    }
                                                    </div>
                                                );
                                            })
                                        }
                            </Col>
                    </Container>
                </section>
            }

            <section className="fullwidth blnz-article-container">
                <Container>
                    <h2 className="mb-2">Artículos relacionados</h2><br />
                    <ArticulosRelacionados
                        items={articulosrel}
                        section="balanz-university"
                        linkBtn="/balanz-university/articulos/"
                        getReadingTime={getReadingTime}
                    />
                </Container>
            </section>
            </SalWrapper>
            <section className="fullwidth semi inversiones py-lg-5 py-sm-4 py-3">
                <Container>
                    <Row className="gx-lg-5 gx-md-4 gx-3 gy-3" xs={1} md={2}>
                        <Col>
                            <article className="semiwidthbg Balanz-cursos p-lg-5 py-md-4 px-md-4 py-3 px-4">
                                <div className="section-content  mx-2">
                                    <h2 className="titulo"><span className="highlightsbold">Balanz University.</span> </h2>
                                    <p className="texto2">Educación financiera para el mundo real.</p>
                                    <p className="texto">Aprendé todo lo que tenés <br/>
                                    que saber para invertir mejor <br/> 
                                    y potenciar tus ahorros.</p>
                                    <div className="cta py-3 py-sm-2 py-md-1">
                                    <Button variant="secondary" text="Ver cursos" onClick={() => { window.open("https://balanzuniversity.com/", "_blank") }} />
                                    </div>
                                </div>
                            </article>
                        </Col>
                        <Col>
                            <article className="abrir-cuenta p-lg-5 py-md-4 px-md-4 py-3 px-4">
                                <div className="section-content mx-2">
                                    <h2 className="titulo" >Abrí hoy tu cuenta de inversión</h2>
                                    <p className="texto">Operá todos los instrumentos del mercado desde la plataforma más completa y segura.</p>
                                    <div className="cta py-3 py-sm-2 py-md-1">
                                        <a href="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1399" className="btn btn-secondary">Abrir cuenta</a>
                                       
                                    </div>
                                </div>
                            </article>
                        </Col>
                    </Row>
                </Container>
            </section>
           
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} img={imgUrl} childrem={page} category={slug}></Layout>
        </main>
    );
};

export default ArticuloPage;